







































































































import { InputSetups } from '../mixins/input-setups'
import { AnchorData } from "@/includes/logic/Anchors/anchors";
import Placeholders from "@/mixins/placeholders/placeholders";
import { warningNotification } from '@/includes/NotificationService'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FileType } from 'piramis-base-components/src/components/File/types'

import { cloneDeep } from 'lodash'
import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'

@Component({
  data() {
    return {
      FileType
    }
  }
})
export default class AnchorCard extends Mixins(UseFields, InputSetups, Placeholders) {
  @VModel({ 'type': Object }) anchorData!: AnchorData

  anchorDataCopy: AnchorData | null = null

  @Watch('anchorDataCopy', { 'deep': true })
  onAnchorDataCopyChange(value: AnchorData): void {
    this.anchorData = value
  }

  get anchorTrigger():Array<string> {
    if (this.anchorDataCopy && this.anchorDataCopy.trigger !== '') {
      return [ this.anchorDataCopy.trigger ]
    }

    return []
  }

  set anchorTrigger(value: Array<string>) {
    if (this.anchorDataCopy && value.length < 2) {
      this.anchorDataCopy.trigger = value.join('').trim()
    } else {
      warningNotification(this.$t('anchor_value_exists_warning').toString())
    }
  }

  created(): void {
    this.anchorDataCopy = cloneDeep(this.anchorData)
  }
}
