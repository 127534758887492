import { EditorData } from "piramis-base-components/src/logic/types";

export type AnchorData = {
  trigger: string
  reaction: Array<EditorData>
  delete_previous: boolean
  enabled: boolean
  strict: boolean
  send_as_reply: boolean
  remove_request: boolean
}

export function createAnchor(anchorData?: AnchorData) {
  let anchor: AnchorData = {
    trigger: "",
    reaction: [],
    delete_previous: false,
    enabled: false,
    strict: false,
    send_as_reply: false,
    remove_request: false,
  }
  if (anchorData) anchor = Object.assign(anchor, anchorData)
  return JSON.parse(JSON.stringify(anchor))
}
