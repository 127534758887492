var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.anchorDataCopy)?_c('a-card',{staticClass:"relative"},[_c('a-button',{attrs:{"slot":"extra","type":"danger","icon":"delete"},on:{"click":function($event){return _vm.$emit('delete')}},slot:"extra"}),_c('chips-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': this,
        'key': 'anchorTrigger',
        'prefix': 'anchor_item_',
        'validation': 'required',
      }
    }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': Object.assign({}, {'model': _vm.anchorDataCopy,
        'key': 'reaction',
        'placeholders': _vm.BASE_PLACEHOLDERS,
        'prefix': 'anchor_item_',
        'validation': 'required',
        'availableButtonsTypes': _vm.defaultMessageEditorButtons,
        'targetToUpload': function () { return _vm.groupUploadTarget; },
        'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
        'base-api-url': 'api.chatkeeper.app/cabinet',
        'settingsStructure': _vm.defaultMessageEditorSettingsStructure,
        options: {
          topics: _vm.topicsAvailable
        }},
        _vm.topicsIfForum,
        {'blacklistMediaButtonTypes': [ _vm.FileType.MessagePreview ]})
    }}}),_c('checkbox-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.anchorDataCopy,
        'key': 'delete_previous',
        'prefix': 'anchor_item_',
      }
    }}}),_c('checkbox-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.anchorDataCopy,
        'key': 'strict',
        'prefix': 'anchor_item_',
      }
    }}}),_c('checkbox-input',{attrs:{"setup":{
      'func': _vm.configInputSetup,
      'args': {
        'model': _vm.anchorDataCopy,
        'key': 'remove_request',
        'prefix': 'anchor_item_',
      }
    }}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.anchorDataCopy.remove_request)?_c('checkbox-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.anchorDataCopy,
          'key': 'send_as_reply',
          'prefix': 'anchor_item_',
        }
      }}}):_vm._e()],1),_c('div',{staticClass:"flex flex-row justify-end mt-base"},[_c('a-button',{attrs:{"type":_vm.anchorDataCopy.enabled ? 'danger' : 'primary'},on:{"click":function($event){_vm.anchorDataCopy.enabled = !_vm.anchorDataCopy.enabled}}},[_vm._v(" "+_vm._s(_vm.anchorDataCopy.enabled ? _vm.$t('anchor_off') : _vm.$t('anchor_on'))+" ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }