






















































import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import PageTitle from '@/components/PageTitle.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from "../../mixins/input-setups";
import TariffsTagsHelper from "../../mixins/TariffsTagsHelper";
import { AnchorData, createAnchor } from "../../includes/logic/Anchors/anchors";
import AnchorCard from '@/components/AnchorCard.vue'

import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { createListConfigItem } from 'piramis-base-components/src/components/Pi/components/List/logic/helpers'
import { UseFields } from "piramis-base-components/src/components/Pi/index";
import { ListItem } from "piramis-base-components/src/components/Pi/components/List/logic/types";
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types';

import { Component, Mixins } from 'vue-property-decorator'
import { debounce } from "lodash";

@Component({
  'components': {
    PageTitle,
    CenteredColumnLayout,
    NotAvailableOptionsOverlay,
    List,
    AnchorCard
  },
  data() {
    return {
      ListDirection
    }
  }
})
export default class Anchors extends Mixins(UseFields, InputSetups, TariffsTagsHelper) {
  anchors: Array<ListItem> = []

  onAnchorsChange = debounce(() => {
    this.saveAnchors()
  }, 300)

  saveAnchors(): void {
    this.$store.commit('pi/EXEC', {
      'fn': () => {
        this.$store.state.chatState.chat.config.anchors = this.anchors.slice().map((item) => {
          const { ...anchorData }: { guid?: string } & AnchorData = item.value
          delete anchorData.guid
          return anchorData
        })
      }
    })
  }

  updateAnchors(): void {
    this.anchors =
      this.$store.state.chatState.chat.config.anchors
        .slice().map((item: AnchorData) => createListConfigItem(createAnchor(item)));
  }

  createNewAnchor() {
    this.anchors = [ createListConfigItem(createAnchor()), ...this.anchors ]
    this.saveAnchors()
  }

  deleteAnchor(index: number) {
    this.$confirm({
      title: this.$t("dialog_anchors_delete_item_title").toString(),
      content: this.$t("dialog_anchors_delete_item_text").toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: async (): Promise<void> => {
        this.anchors.splice(index, 1)
        this.saveAnchors()
      },
    })
  }

  created() {
    this.updateAnchors()
  }
}
