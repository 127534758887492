var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',{staticClass:"relative"},[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('anchors')},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),(!_vm.getTagsByFieldKey('anchors'))?[_c('div',{staticClass:"text-left sm:text-right"},[_c('a-button',{attrs:{"type":"primary","icon":"plus"},on:{"click":function($event){return _vm.createNewAnchor()}}},[_vm._v(" "+_vm._s(_vm.$t('anchor_create_new'))+" ")])],1),_c('list',{staticClass:"mt-5",attrs:{"config":{
        'data': _vm.anchors,
        'paginatedBy': 5,
        'searchFn': function (item, index, text) { return item.value.trigger.includes(text.trim()); },
        'keyFn': function (item) { return item.value.guid; },
        'direction': _vm.ListDirection.Vertical
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c('anchor-card',{on:{"delete":function($event){return _vm.deleteAnchor(index)},"input":_vm.onAnchorsChange},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,false,2534903783)})]:_c('NotAvailableOptionsOverlay',{staticClass:"shadow relative",staticStyle:{"margin":"0 1rem"},attrs:{"tags":_vm.getTagsByFieldKey('anchors'),"video":_vm.$i18n.locale !== 'ru' ?
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_anchors_en.m4v' :
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_anchors_ru.m4v',"description":_vm.$t('option_description_anchors')}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }